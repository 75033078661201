$primaryColor: #3f51b5;
$primaryColorLight: change-color($primaryColor, $lightness: 75%, $saturation: 45%);

$estimatedNavbarHeight: 10px;

:export {
    primaryColor: $primaryColor;
    primaryColorLight: $primaryColorLight;
    estimatedNavbarHeight: $estimatedNavbarHeight;
}

// for a11y purposes, it's useful to use buttons instead of clickable divs.
// But buttons come with default styles that are repetitive to override
// so use this mixin! 
%button-unstyled {
    border: none;
    background-color: inherit;
    outline: none;
    padding: 0;
    border-radius: 0;
}

