@import 'variables.module.scss';
.subtext {
    font-size: 0.8em;
    opacity: 0.75;

}

[class*=-primary-bg-color] {
    background-color: $primaryColor !important;
}

.button-unstyled {
    @extend %button-unstyled;
}

